<template>
  <LoadingBar v-if="loading" />
  <Page v-else :title="pageTitle">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col>
          <v-text-field
            v-model="name"
            :rules="rules.name"
            label="Download Name"
          ></v-text-field>
          <v-textarea
            v-model="description"
            :rules="rules.description"
            label="Download Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Components to Include in Download</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="6"
                    class="flex-column"
                    @drop="onDrop($event, true)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <v-list dense>
                      <v-subheader>Report Components</v-subheader>
                      <v-list-item
                        :key="i"
                        v-for="(item, i) in selectedComponents"
                        @drop="onDrop($event, true, i)"
                        @dragover.prevent
                        @dragenter="dragEnter($event, item)"
                        @dragleave="dragLeave($event, item)"
                        draggable="true"
                        @dragstart="startDrag($event, item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="compName(item.id)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col
                    cols="6"
                    class="flex-column"
                    @drop="onDrop($event, false)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <v-list dense>
                      <v-subheader>Available Components</v-subheader>
                      <v-list-item
                        :key="i"
                        v-for="(item, i) in availableComponents"
                        @drop="onDrop($event, false)"
                        @dragover.prevent
                        @dragenter.prevent
                        draggable="true"
                        @dragstart="startDrag($event, item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="compName(item.id)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="pa-5">
        <v-btn @click="cancel()" color="secondary">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveCustomCsv()">
          Save
        </v-btn>
      </v-row>
    </v-form>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
import rules from "@/utils/rules";
import { polyfill } from "mobile-drag-drop";

polyfill({});

export default {
  name: "EditCsvDownloads",
  components: { LoadingBar, Page },
  data() {
    return {
      loading: true,
      valid: false,
      selectedIds: [],
      rules: {
        name: [rules.required, rules.maxLength(255)],
        description: [rules.maxLength(255)]
      }
    };
  },
  computed: {
    ...mapState("csvs", ["detailItem"]),
    ...mapState("baseData", ["test_components"]),
    ...mapFields("csvs", [
      "detailItem.id",
      "detailItem.name",
      "detailItem.description"
    ]),
    selectedComponents() {
      let comps = [];
      this.selectedIds.forEach(id => {
        comps.push(this.test_components.items.find(item => item.id === id));
      });
      return comps;
    },
    availableComponents() {
      return this.test_components.items.filter(
        item => !this.selectedIds.includes(item.id)
      );
    },
    isNew() {
      return !this.id;
    },
    pageTitle() {
      return `${this.isNew ? "Add" : "Edit"} Custom CSV Download`;
    }
  },
  methods: {
    ...mapMutations("csvs", ["clearDetailItem"]),
    ...mapActions("csvs", ["getCsv", "addCsv", "editCsv"]),
    ...mapActions("baseData", ["getTestComponents"]),
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemId", item.id);
    },
    dragEnter(evt) {
      if (evt.clientY > evt.target.height / 2) {
        evt.target.style.marginBottom = "10px";
      } else {
        evt.target.style.marginTop = "10px";
      }
    },
    dragLeave(evt) {
      evt.target.style.marginTop = "2px";
      evt.target.style.marginBottom = "2px";
    },
    onDrop(evt, includeIt, idx = -1) {
      if (evt.target.style.marginTop === "10px") {
        evt.target.style.marginTop = "2px";
      }
      if (evt.target.style.marginBottom === "10px") {
        evt.target.style.marginBottom = "2px";
      }
      const id = parseInt(evt.dataTransfer.getData("itemId"));
      if (includeIt) {
        this.add(id);
        this.moveItem(this.selectedIds.indexOf(id), idx);
      } else {
        this.remove(id);
      }
      evt.stopPropagation();
    },
    add(id) {
      if (!this.selectedIds.includes(id)) {
        this.selectedIds.push(id);
      }
    },
    remove(id) {
      const idx = this.selectedIds.indexOf(id);
      if (idx >= 0) {
        this.selectedIds.splice(idx, 1);
      }
    },
    moveItem(from, to) {
      if (to < 0) to = this.selectedIds.length;
      this.selectedIds.splice(to, 0, this.selectedIds.splice(from, 1)[0]);
    },
    compName(id) {
      return this.test_components.items.find(item => item.id === id).title;
    },
    cancel() {
      this.clearDetailItem();
      this.$router.push("/reports/custom-csvs");
    },
    loadCsv(id) {
      if (id && id !== this.id) {
        return this.getCsv(id).catch(() => {
          this.cancel();
        });
      } else {
        if (!id && this.id) {
          this.clearDetailItem();
        }
        return Promise.resolve();
      }
    },
    saveCustomCsv() {
      if (!this.$refs.form.validate()) return false;
      let promise,
        i = 0,
        detailItem = {};
      Object.assign(detailItem, this.detailItem);
      detailItem.custom_csv_test_components = [];
      this.selectedIds.forEach(id => {
        detailItem.custom_csv_test_components[i] = { test_component_id: id };
        i++;
      });
      if (this.isNew) {
        promise = this.addCsv(detailItem);
      } else {
        promise = this.editCsv(detailItem);
      }
      promise
        .then(() => {
          this.clearDetailItem();
          this.$router.push("/reports/custom-csvs");
        })
        .catch(e => {
          console.error(e);
        });
    }
  },
  watch: {
    $route(to) {
      this.loadCsv(to.params.id);
    }
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.loadCsv(this.$route.params.id),
      this.getTestComponents()
    ]).then(() => {
      if (!this.isNew) {
        this.detailItem.custom_csv_test_components.forEach(item => {
          this.add(item.test_component_id);
        });
      }
      this.loading = false;
    });
  }
};
</script>

<style scoped>
.flex-column {
  background-color: #efefef;
}
.v-list {
  background-color: #efefef;
}
.v-list-item {
  padding-top: 1px;
  background-color: #fff;
  border: solid 2px #efefef;
  border-left-width: 3px;
  border-right-width: 3px;
  border-radius: 8px;
}
.v-list-item__content {
  pointer-events: none;
}
</style>
